<template>
  <li
    class="symptoms-item hidden cursor-pointer rounded-lg bg-white px-2 py-3 leading-4 shadow-md md:bg-transparent md:py-2 md:shadow-none md:[&:nth-child(-n+14)]:block [&:nth-child(-n+6)]:block"
  >
    <NuxtLink
      :to="symptomUrl"
      class="flex flex-col flex-nowrap items-center text-supporting-black-87 hover:no-underline"
    >
      <div
        class="relative max-w-[64px] overflow-hidden rounded-full md:max-w-[90px] [&>img]:transition-transform [&>img]:ease-in-out [&>img]:hover:scale-125"
      >
        <img
          :alt="`${symptom.symptomListName} icon`"
          :src="symptomIcon"
          width="150"
          height="150"
        />
      </div>
      <div class="mt-auto max-w-full break-words pt-3 text-center text-[15px]">
        {{ symptom.symptomListName }}
      </div>
    </NuxtLink>
  </li>
</template>

<script setup lang="ts">
const props = defineProps({
  symptom: {
    type: Object,
    default: () => {},
  },
});
const localePath = useLocalePath();

const symptomUrl = ref('');
if (props.symptom.symptomEntry && props.symptom.symptomEntry[0]) {
  symptomUrl.value = `${localePath('symptom')}#s-${props.symptom.symptomEntry[0].id}`;
} else if (props.symptom.symptomUrl) {
  symptomUrl.value = props.symptom.symptomUrl;
}

const symptomIcon = ref(
  `https://cms.firstvet.com/assets/static/icons/symptom_40/${props.symptom.symptomListIcon}.svg`
);
</script>
