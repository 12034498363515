<template>
  <section
    class="section-symptoms-list overflow-hidden bg-background-extra-light py-6 md:pb-14 md:pt-10"
    data-test-id="symptoms-list"
  >
    <div class="relative mx-auto mb-6 flex max-w-5xl flex-wrap">
      <div class="w-full">
        <h2
          v-if="block.heading"
          class="px-2 pb-7 text-center text-2xl md:text-3xl"
        >
          {{ block.heading }}
        </h2>

        <div
          v-if="block.textArea"
          class="mx-auto max-w-[720px] px-2 pb-4 text-center text-base empty:pb-1"
        >
          {{ block.textArea }}
        </div>

        <ul
          class="grid grid-cols-3 justify-center gap-3 px-3 md:grid-cols-[repeat(auto-fit,12.6%)] md:gap-5 md:px-0"
        >
          <SymptomListItem
            v-for="symptom in block.symptomList"
            :key="symptom.id"
            :symptom="symptom"
          />
        </ul>
      </div>
    </div>

    <button
      v-if="numberOfSymptoms > iMob"
      id="toggleVisibility"
      :class="`${toggleVisibilityButtonClasses} ${toggleVisibilityButtonClassesExtra}`"
      @click="toggleSymptomsVisibility"
    >
      <span class="symptoms-more">{{ $t('editorial.show_all') }}</span>
      <span class="symptoms-less hidden">{{ $t('editorial.show_less') }}</span>
      <DynamicPictogram
        id="toggleVisibilityChevron"
        class="ml-2 -rotate-90"
        name="chevron"
      />
    </button>
  </section>
</template>

<script setup lang="ts">
const props = defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  globals: {
    type: Object,
    default: () => {},
  },
});

const toggleVisibilityButtonClasses =
  'text-primary-default hover:text-primary-hover my-0 mx-auto flex cursor-pointer items-center rounded-lg bg-white p-4 active:shadow-none';
const iMob = 6; // Initial number of displayed items on mobile devices
const iDesk = 14; // Initial number of displayed items on desktop #}
const numberOfSymptoms = props.block.symptomList.length;
let toggleVisibilityButtonClassesExtra = '';
if (numberOfSymptoms > iMob && numberOfSymptoms < iDesk) {
  toggleVisibilityButtonClassesExtra = 'md:hidden lg:hidden xl:hidden';
}

// TODO: Handle this through the template and conditional classes rather than accessing the DOM
const toggleSymptomsVisibility = () => {
  const symptomsItems = document.querySelectorAll('.symptoms-item');
  const more = document.querySelector('.symptoms-more');
  const less = document.querySelector('.symptoms-less');
  const chevron = document.getElementById('toggleVisibilityChevron');

  // Show/hide symptoms
  symptomsItems.forEach((item) => {
    if (item.classList.contains('hidden')) {
      item.classList.add('was-hidden');
      item.classList.remove('hidden');
    } else if (item.classList.contains('was-hidden')) {
      item.classList.add('hidden');
      item.classList.remove('was-hidden');
    }
  });

  // Chevron
  if (chevron?.classList.contains('-rotate-90')) {
    chevron.classList.remove('-rotate-90');
    chevron.classList.add('rotate-90');
  } else if (chevron?.classList.contains('rotate-90')) {
    chevron.classList.remove('rotate-90');
    chevron.classList.add('-rotate-90');
  }

  // Button text
  if (more) {
    more.classList.toggle('hidden');
  }
  if (less) {
    less.classList.toggle('hidden');
  }
};
</script>
